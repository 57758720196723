<template>
    <div class="home">
        <Toolbar />

        <main>
            <!-- 左侧组件列表 -->
            <section class="left">
                <ComponentList />
            </section>
            <!-- 中间画布 -->
            <section class="center">
                <div class="content" @drop="handleDrop" @dragover="handleDragOver" 
                @mousedown="handleMouseDown" @mouseup="deselectCurComponent">
                    <Editor />
                </div>
            </section>
            <!-- 右侧属性列表 -->
            <section class="right">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="属性" name="attr">
                        <AttrList v-if="curComponent" />
                        <p v-else class="placeholder">请选择组件</p>
                    </el-tab-pane>
                    <el-tab-pane label="动画" name="animation">
                        <AnimationList v-if="curComponent" />
                        <p v-else class="placeholder">请选择组件</p>
                    </el-tab-pane>
                    <el-tab-pane label="事件" name="events">
                        <EventList v-if="curComponent" />
                        <p v-else class="placeholder">请选择组件</p>
                    </el-tab-pane>
                </el-tabs>
            </section>
        </main>
        <el-dialog
        title="提示信息"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
            <span>快去登录，为你的ta完成页面设计吧</span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="openLoginPage">立即登录</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Editor from '@/components/Editor/index'
import ComponentList from '@/components/ComponentList' // 左侧列表组件
import AttrList from '@/components/AttrList' // 右侧属性列表
import AnimationList from '@/components/AnimationList' // 右侧动画列表
import EventList from '@/components/EventList' // 右侧事件列表
import componentList from '@/custom-component/component-list' // 左侧列表数据
import Toolbar from '@/components/Toolbar'
import { deepCopy } from '@/utils/utils'
import { mapState } from 'vuex'
import generateID from '@/utils/generateID'
import { listenGlobalKeyDown } from '@/utils/shortcutKey'
import axios from 'axios'

export default {
    components: { Editor, ComponentList, AttrList, AnimationList, EventList, Toolbar },
    data() {
        return {
            activeName: 'attr',
            reSelectAnimateIndex: undefined,
            dialogVisible: false,
            formLabelWidth: '120px',
            loginUser: {
                email: '',
                password: '',
            },
            registerUser: {
                userName: '',
                email: '',
                password: '',
                repassword: '',
            },
        }
    },
    computed: mapState([
        'componentData',
        'curComponent',
        'isClickComponent',
        'canvasStyleData',
    ]),
    created() {
        this.restore()
        // 全局监听按键事件
        listenGlobalKeyDown()
        let token = this.$route.query.token
        if (!token) {
            this.dialogVisible = true
        }
    },
    methods: {
        openLoginPage() {
            window.location.href='http://loveyou520.top/login'
        },
        handleClose() {
            this.$message({
                message: '温馨提示，请登录后使用',
                type: 'warning',
            })
        },
        restore() {
            let this_ = this
            let templateId = this.$route.query.templateId
            if (!templateId) {
                return
            }

            let token = this.$route.query.token
            axios.post(this_.hostUrl+'/api/template/templateById', {
                id: templateId,
            }, {
                headers: {
                    token,
                },
            }).then(res => {
                if (!res.data.data) {
                    return
                }

                if (res.data.data&&res.data.data.canvasData) {
                    this_.$store.commit('setComponentData', this_.resetID(JSON.parse(res.data.data.canvasData)))
                }
                if (res.data.data&&res.data.data.canvasStyle) {
                    this_.$store.commit('setCanvasStyle', JSON.parse(res.data.data.canvasStyle))
                }
            })
        },

        resetID(data) {
            data.forEach(item => {
                item.id = generateID()
            })

            return data
        },

        handleDrop(e) {
            e.preventDefault()
            e.stopPropagation()
            const component = deepCopy(componentList[e.dataTransfer.getData('index')])
            component.style.top = e.offsetY
            component.style.left = e.offsetX
            component.id = generateID()
            this.$store.commit('addComponent', { component })
            this.$store.commit('recordSnapshot')

            // 激活当前元素
            this.$store.commit('setCurComponent', { 
                component,
            })
        },

        handleDragOver(e) {
            e.preventDefault()
            e.dataTransfer.dropEffect = 'copy'
        },

        handleMouseDown() {
            this.$store.commit('setClickComponentStatus', false)
        },

        deselectCurComponent() {
            this.$store.commit('hideContextMenu')
            if (!this.isClickComponent) {
                this.$store.commit('setCurComponent', { component: null, index: null })
            }
        },
    },
}

</script>

<style lang="scss">
.home {
    height: 100vh;
    background: #fff;

    main {
        height: calc(100% - 64px);
        position: relative;

        .left {
            position: absolute;
            height: 100%;
            width: 200px;
            left: 0;
            top: 0;
            padding-top: 10px;
        }

        .right {
            position: absolute;
            height: 100%;
            width: 262px;
            right: 0;
            top: 0;
        }

        .center {
            margin-left: 200px;
            margin-right: 262px;
            background: #f5f5f5;
            height: 100%;
            overflow: auto;
            padding: 20px;

            .content {
                width: 100%;
                height: 100%;
                overflow: auto;
            }
        }
    }

    .placeholder {
        text-align: center;
        color: #333;
    }
}
</style>
