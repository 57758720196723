<template>
    <div class="attr-list">
        <div class="common-property">
            <i @click="alignMethod('left')" title="水平居左" class="icon-item iconfont icon-juzuo"></i>
            <i @click="alignMethod('horizontalcenter')" title="水平居中" class="icon-item iconfont icon-juzhongduiqi"></i>
            <i @click="alignMethod('right')" title="水平居右" class="icon-item iconfont icon-juyou"></i>
            <i @click="alignMethod('top')" title="顶部对齐" class="icon-item iconfont icon-jushangduiqi"></i>
            <i @click="alignMethod('verticalCenter')" title="垂直居中" class="icon-item iconfont icon-chuizhijuzhong"></i>
            <i @click="alignMethod('bottom')" title="底部对齐" class="icon-item iconfont icon-juxiaduiqi"></i>
        </div>
        <el-divider></el-divider>
        <el-form>
            <el-form-item v-for="(key, index) in styleKeys.filter(item => item != 'rotate')" :key="index" :label="map[key]">
                <el-color-picker v-if="key == 'borderColor'" v-model="curComponent.style[key]"></el-color-picker>
                <el-color-picker v-else-if="key == 'color'" v-model="curComponent.style[key]"></el-color-picker>
                <el-color-picker v-else-if="key == 'backgroundColor'" v-model="curComponent.style[key]"></el-color-picker>
                <el-select v-else-if="key == 'borderStyle'" v-model="curComponent.style[key]">
                    <el-option
                        v-for="item in borderStyleList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
                <el-select v-else-if="key == 'width'" v-model="widthValue" @change="widthValChanged">
                    <el-option
                        v-for="item in 10"
                        :key="item"
                        :label="item*10+'%'"
                        :value="item*10"
                    ></el-option>
                </el-select>
                <el-select v-else-if="key == 'opacity'" v-model="curComponent.style[key]">
                    <el-option
                        v-for="item in 10"
                        :key="item"
                        :label="item"
                        :value="item/10"
                    ></el-option>
                </el-select>
                <el-select v-else-if="key == 'fontSize'" v-model="curComponent.style[key]">
                    <el-option
                        v-for="item in 10"
                        :key="item"
                        :label="item*5"
                        :value="item*5"
                    ></el-option>
                </el-select>
                <el-select v-else-if="key == 'fontWeight'" v-model="curComponent.style[key]">
                    <el-option
                        v-for="item in 8"
                        :key="item"
                        :label="item*100"
                        :value="item*100"
                    ></el-option>
                </el-select>
                <el-select v-else-if="key == 'lineHeight'" v-model="curComponent.style[key]">
                    <el-option
                        v-for="item in 3"
                        :key="item"
                        :label="item"
                        :value="item"
                    ></el-option>
                </el-select>
                <el-select v-else-if="key == 'letterSpacing'" v-model="curComponent.style[key]">
                    <el-option
                        v-for="item in 10"
                        :key="item"
                        :label="item"
                        :value="item"
                    ></el-option>
                </el-select>
                <el-select v-else-if="key == 'verticalAlign'" v-model="curComponent.style[key]">
                    <el-option
                        v-for="item in verticalAlignList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
                <el-select v-else-if="key == 'textAlign'" v-model="curComponent.style[key]">
                    <el-option
                        v-for="item in horizonAlignList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
                <el-select v-else-if="key == 'borderRadius'" v-model="curComponent.style[key]">
                    <el-option
                        v-for="item in 10"
                        :key="item"
                        :label="item*2"
                        :value="item*2"
                    ></el-option>
                </el-select>
                <el-select v-else-if="key == 'borderWidth'" v-model="curComponent.style[key]">
                    <el-option
                        v-for="item in 10"
                        :key="item"
                        :label="item"
                        :value="item"
                    ></el-option>
                </el-select>

                <!-- 图片上传 -->
                <el-upload
                  v-else-if="key == 'picSrc'"
                  action="/api/file/upload"
                  accept="image/*"
                  :on-success="handleAvatarSuccess"
                  :show-file-list="false">
                  <el-button size="small" type="plain">上传<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>

                <!-- 音乐上传  -->
                <el-upload
                  v-else-if="key == 'musicSrc'"
                  action="/api/file/upload"
                  accept="audio/mp3"
                  :on-success="handleMusicSuccess"
                  :on-progress="musicUploading"
                  :show-file-list="false">
                  <el-button size="small" type="plain">上传<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>
                
                <el-input v-else-if="key=='linkUrl'" type="text" v-model="curComponent.linkUrl" />
                <el-input v-else type="text" v-model="curComponent.style[key]" />
            </el-form-item>
            <el-form-item v-if="curComponent.component=='Link'" label="链接地址">
                <el-input type="text" v-model="curComponent.linkUrl" />
            </el-form-item>
            <el-form-item v-if="curComponent.component=='Link'" label="链接描述">
                <el-input type="text" v-model="curComponent.linkLable" />
            </el-form-item>
            <el-form-item label="内容" v-if="curComponent && excludes.includes(curComponent.component)">
                <el-input type="textarea" v-model="curComponent.propValue" />
            </el-form-item>

            <!-- 轮播图上传 -->
            <el-form-item v-if="curComponent.component=='Carousel'" label="选择图片">
                <el-upload
                  action="/api/file/upload"
                  :on-preview="carouselHandlePreview"
                  accept="image/*"
                  multiple
                  :limit="5"
                  :on-success="carouselUploadSuccess"
                  :on-remove="removeCarouselImg"
                  :before-remove="removeCarouselImgBefor"
                  :show-file-list="true">
                  <el-button size="small" type="plain">上传<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>
            </el-form-item>

            <!-- 视频上传 -->
            <el-form-item v-if="curComponent.component=='Video'" label="上传mp4">
                <el-upload
                  action="/api/file/upload"
                  accept="audio/mp4"
                  multiple
                  :limit="5"
                  :on-success="videoUploadSuccess"
                  :show-file-list="false">
                  <el-button size="small" type="plain">上传<i class="el-icon-upload el-icon--right"></i></el-button>
                </el-upload>
            </el-form-item>

            <!-- 时间线 -->
            <el-divider></el-divider>

            <div v-if="curComponent.component=='TimeLine'">
                <div class="time-lineitem" v-for="(item, index) in timeLineList" :key="index">
                    <el-form-item label="时间">
                        <el-date-picker
                        v-model="item.dateTime"
                        format="yyyy-MM-dd" 
                        value-format="yyyy-MM-dd" 
                        type="date"
                        placeholder="选择日期" />
                    </el-form-item>
                    <el-form-item label="标题">
                        <el-input type="text" v-model="item.title" />
                    </el-form-item>
                    <el-form-item label="内容">
                        <el-input type="textarea" v-model="item.content" />
                    </el-form-item>
                </div>

                 <el-button @click="addTimeLineItem" type="primary" plain>新增</el-button>
                 <el-button @click="delTimeLineItem" type="danger" plain>删除</el-button>

            </div>
        </el-form>
    </div>
</template>

<script>

export default {
    data() {
        return {
            widthValue: '',
            excludes: ['v-text'], // 这些组件显示内容
            borderStyleList: [
                {
                    label: '虚线',
                    value: 'dashed',
                },
                {
                    label: '实线',
                    value: 'solid',
                },
            ],
            horizonAlignList: [
                {
                    label: '居左',
                    value: 'left',
                },
                {
                    label: '居中',
                    value: 'center',
                },
                {
                    label: '居右',
                    value: 'right',
                },
            ],
            verticalAlignList: [
                {
                    label: '上对齐',
                    value: 'top',
                },
                {
                    label: '居中',
                    value: 'middle',
                },
                {
                    label: '下对齐',
                    value: 'bottom',
                },
            ],
            map: {
                color: '颜色',
                backgroundColor: '背景色',
                borderWidth: '边框宽度',
                borderColor: '边框颜色',
                borderRadius: '边框半径',
                fontSize: '字体大小',
                fontWeight: '字体粗细',
                lineHeight: '行高',
                letterSpacing: '字间距',
                verticalAlign: '垂直对齐',
                opacity: '透明度',
                picSrc: '选择图片',
                linkUrl: '链接描述',
                linkLable: '链接地址',
                borderStyle: '边框样式',
                musicSrc: '请选择音乐（mp3）',
                width: '宽度',
                textAlign: '水平对齐',
            },
        }
    },
    methods: {
        delTimeLineItem() {
            let currentEle = this.$store.state.curComponent
            if (currentEle.propValue.length == 1) {
                this.$message({
                    message: '最少为一个故事',
                    type: 'error',
                })
                return
            }

            currentEle.propValue.pop()
        },

        addTimeLineItem() {
            let currentEle = this.$store.state.curComponent
            currentEle.propValue.push({
                dateTime: '2022-08-06',
                title: '故事标题',
                content: '故事描述',
            })
        },
        widthValChanged(val) {
            let currentEle = this.$store.state.curComponent
            currentEle.style.width = this.$store.state.canvasStyleData.width*(val/100)
        },
        carouselHandlePreview(file) {
            console.log(file)
        },
        musicUploading(event, file, fileList) {

        },
        handleMusicSuccess(res, file) {
            this.$message({
                message: '上传成功',
                type: 'success',
            })
            let currentEle = this.$store.state.curComponent
            currentEle.propValue = res.data.url
        },
        videoUploadSuccess(res, file) {
            this.$message({
                message: '上传成功',
                type: 'success',
            })
            let currentEle = this.$store.state.curComponent
            currentEle.propValue = res.data.url
        },
        carouselUploadSuccess(res, file) {
            let currentEle = this.$store.state.curComponent
            if (currentEle.propValue.length==1&&currentEle.propValue[0].includes('banner_default')) {
                currentEle.propValue.length = 0
                currentEle.style.width = this.$store.state.canvasStyleData.width
            }
            currentEle.propValue.push(res.data.url)
        },

        removeCarouselImg(file, fileList) {
            if (fileList.length==0) {
                return
            }

            let currentEle = this.$store.state.curComponent
            currentEle.propValue.length = 0

            for (let i = 0; i < fileList.length; i++) {
                let item = fileList[i]
                currentEle.propValue.push(item.response.data.url)
            }
        },
        removeCarouselImgBefor(file, fileList) {
            if (fileList.length==1) {
                this.$message({
                    message: '轮播图中最少需要一张图片',
                    type: 'error',
                })
                return false
            }

            return true
        },
        handleAvatarSuccess(res, file) {
            let currentEle = this.$store.state.curComponent
            currentEle.propValue = res.data.url

            let relHeight = res.data.height/(res.data.width/300)
            
            // 对图片进行缩小
            currentEle.style.width = 300
            currentEle.style.height = relHeight
        },
        alignMethod(aligin) {
            let currentEle = this.$store.state.curComponent
            let canvasStyle = this.$store.state.canvasStyleData
            let eleWidth = currentEle.style.width

            if (aligin=='left') {
                currentEle.style.left = 0
            }

            if (aligin=='horizontalcenter') {
                currentEle.style.left = (canvasStyle.width - eleWidth)/2
            }

            if (aligin=='right') {
                currentEle.style.left = canvasStyle.width - eleWidth
            }

            if (aligin=='top') {
                currentEle.style.top = 0
            }

            if (aligin=='verticalCenter') {
                currentEle.style.top = (canvasStyle.height-currentEle.style.height)/2
            }

            if (aligin=='bottom') {
                currentEle.style.top = canvasStyle.height-currentEle.style.height
            }
        },
    },
    computed: {
        timeLineList() {
            let propValue = this.$store.state.curComponent? this.$store.state.curComponent.propValue : []
            return propValue
        },
        styleKeys() {
            let style = this.$store.state.curComponent? Object.keys(this.$store.state.curComponent.style) : []
            style = style.filter(item => this.map[item])
            return style
        },
        curComponent() {
            return this.$store.state.curComponent
        },
    },
}
</script>

<style lang="scss" scoped>
.attr-list {
    overflow: auto;
    padding: 20px;
    padding-top: 0;
    height: 100%;
}
.icon-item{
    margin-left: 10px;
}
</style>