var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editor",class:{ edit: _vm.isEdit, 'show-border': _vm.changeBgSize },style:({
        width: _vm.canvasStyleData.width + 'px',
        height: _vm.canvasStyleData.height + 'px',
        transform: 'scale(' + _vm.canvasStyleData.scale + ')',
        background: _vm.canvasStyleData.background
    }),attrs:{"id":"editor"},on:{"contextmenu":_vm.handleContextMenu,"mousedown":_vm.handleMouseDown}},[(_vm.showGrid)?_c('Grid'):_vm._e(),_c('div',{ref:"imageTofile"},_vm._l((_vm.componentData),function(item,index){return _c('Shape',{key:item.id,class:{ lock: item.isLock },style:(_vm.getShapeStyle(item.style)),attrs:{"defaultStyle":item.style,"active":item === _vm.curComponent,"element":item,"index":index}},[(item.component == 'v-text')?_c(item.component,{tag:"component",staticClass:"component",style:(_vm.getComponentStyle(item.style)),attrs:{"propValue":item.propValue,"element":item,"id":'component' + item.id},on:{"input":_vm.handleInput}}):(item.component == 'Link')?_c(item.component,{tag:"component",staticClass:"component",style:(_vm.getComponentStyle(item.style)),attrs:{"linkLable":item.linkLable,"linkUrl":item.linkUrl,"element":item,"id":'component' + item.id}}):_c(item.component,{tag:"component",staticClass:"component1",style:(_vm.getComponentStyle(item.style)),attrs:{"propValue":item.propValue,"element":item,"id":'component' + item.id}})],1)}),1),_c('ContextMenu'),_c('MarkLine'),_c('Area',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowArea),expression:"isShowArea"}],attrs:{"start":_vm.start,"width":_vm.width,"height":_vm.height}}),(_vm.changeBgSize)?_c('div',{staticClass:"border-point",style:({
        left: _vm.canvasStyleData.width/2 + 'px',
    }),on:{"mousedown":_vm.borderPointMouseDown}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }