<template>
    <div>
        <div class="toolbar">
            <i @click="save" title="保存" class="iconfont icon-baocun"></i>
            <i @click="dele" title="删除" class="iconfont icon-shanchu"></i>
            <i @click="undo" title="回退" class="iconfont icon-chexiao"></i>
            <i @click="redo" title="撤销" class="iconfont icon-zhongzuo"></i>
            <i @click="preview" title="预览" class="iconfont icon-icon_yulan"></i>
            <i @click="clearCanvas" title="清空" class="iconfont icon-qingkong"></i>
            <i @click="compose" title="组合" class="iconfont icon-zuhe" :disabled="!areaData.components.length"></i>
            <i @click="decompose" title="拆分" class="iconfont icon-chaifen"
            :disabled="!curComponent || curComponent.isLock || curComponent.component != 'Group'"></i>

            <i @click="lock" :disabled="!curComponent || curComponent.isLock" title="锁定" class="iconfont icon-suoding_o"></i>
            <i @click="unlock" :disabled="!curComponent || !curComponent.isLock" title="解锁" class="iconfont icon-jiesuo"></i>
            <div class="canvas-config">
                <span>手机类型 </span>
                <el-select style="width:100px" v-model="canvasStyleData.width" size="mini">
                    <el-option label="iphone6/7/8/X/SE" :value="375"></el-option>
                    <el-option label="iphone6/7/8(plus)/XR" :value="414"></el-option>
                    <el-option label="iphone12Pro" :value="390"></el-option>
                </el-select>
                <span>* </span>
                <el-select style="width:100px" v-model="canvasStyleData.height" size="mini">
                    <el-option
                        v-for="item in 10"
                        :key="item"
                        :label="item*200"
                        :value="item*200"
                    ></el-option>
                </el-select>
            </div>
            <div class="canvas-config">
                <span>画布比例 </span>
                <el-select style="width:80px" v-model="canvasStyleData.scale" size="mini">
                    <el-option
                        v-for="item in 10"
                        :key="item"
                        :label="item*10"
                        :value="item/10"
                    ></el-option>
                </el-select>
            </div>
            <div class="canvas-config">
              <span>背景颜色 </span>
              <el-color-picker v-model="canvasStyleData.background" size="mini" show-alpha></el-color-picker>
            </div>
            <div class="canvas-config">
                <el-checkbox @change="showGridChange" v-model="showGrid">显示网格</el-checkbox>
            </div>
            <div class="canvas-config">
                <el-checkbox @change="onChangeBgSize" v-model="changeBgSize">调整画布</el-checkbox>
            </div>
            <!-- <el-switch
                @change="showGridChange"
                v-model="showGrid"
                active-text="显示网格"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
            <el-switch
                @change="onChangeBgSize"
                v-model="changeBgSize"
                active-text="调整画布"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch> -->
        </div>

        <!-- 预览 -->
        <Preview v-model="isShowPreview" @change="handlePreviewChange" />

        <el-dialog title="网页信息" :visible.sync="dialogFormVisible">
          <el-form :model="form">
            <el-form-item label="域名" :label-width="formLabelWidth">
              <el-input v-model="form.domain" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="示例" :label-width="formLabelWidth">
               <el-link target="_blank" v-bind:href="'http://'+form.domain+'.loveyou520.top/'" type="primary">http://{{form.domain}}.loveyou520.top/</el-link>
            </el-form-item>
            <el-form-item label="模板名称" :label-width="formLabelWidth">
              <el-input
              v-model="form.tempateName"
              placeholder="心爱的你"
              autocomplete="off">
              </el-input>
            </el-form-item>
            <el-form-item label="介绍" :label-width="formLabelWidth">
              <el-input
              v-model="form.summary"
              placeholder="亲爱的杨小姐"
              autocomplete="off">
              </el-input>
            </el-form-item>
            <el-form-item label="有效期" :label-width="formLabelWidth">
              <el-select v-model="form.effectivePeriod" v-value="1" placeholder="请选择有效期">
                <el-option label="一月" value="1" aria-checked="true"></el-option>
                <!-- <el-option label="五月" value="5"></el-option>
                <el-option label="一年" value="12"></el-option>
                <el-option label="三年" value="36"></el-option>
                <el-option label="永久" value="9999"></el-option> -->
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="templateSave">确 定</el-button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Preview from '@/components/Editor/Preview'
import eventBus from '@/utils/eventBus'
import axios from 'axios'
import html2canvas from 'html2canvas'

export default {
    components: { Preview },
    data() {        
        return {
            showGrid: true,
            changeBgSize: false,
            isShowPreview: false,
            dialogFormVisible: false,
            form: {
                domain: null,
                summary: null,
                tempateName: null,
                effectivePeriod: '1',
            },
            formLabelWidth: '120px',
        }
    },
    computed: mapState([
        'componentData',
        'canvasStyleData',
        'areaData',
        'curComponent',
    ]),
    created() {
        eventBus.$on('preview', this.preview)
        eventBus.$on('save', this.save)
        eventBus.$on('clearCanvas', this.clearCanvas)
    },
    methods: {
        onChangeBgSize(val) {
            this.$store.commit('showChangeBgSize', val)
        },
        showGridChange(val) {
            this.$store.commit('changeShowGrid', val)
        },
        dele() {
            this.$store.commit('deleteComponent')
            this.$store.commit('recordSnapshot')
        },
        lock() {
            this.$store.commit('lock')
        },

        unlock() {
            this.$store.commit('unlock')
        },

        compose() {
            this.$store.commit('compose')
            this.$store.commit('recordSnapshot')
        },

        decompose() {
            this.$store.commit('decompose')
            this.$store.commit('recordSnapshot')
        },
        
        undo() {
            this.$store.commit('undo')
        },

        redo() {
            this.$store.commit('redo')
        },

        preview() {
            this.isShowPreview = true
            this.$store.commit('setEditMode', 'preview')
        },

        imageToFile() {
            // 手动创建一个canvas标签
            const canvas = document.createElement('canvas')
            // 获取父标签，意思是这个标签内的 DOM 元素生成图片
            // imageTofile是给截图范围内的父级元素自定义的ref名称
            let canvasBox = this.$refs.imageTofile
            // 获取父级的宽高
            const width = parseInt(window.getComputedStyle(canvasBox).width)
            const height = parseInt(window.getComputedStyle(canvasBox).height)
            // 宽高 * 2 并放大 2 倍 是为了防止图片模糊
            canvas.width = width * 2
            canvas.height = height * 2
            canvas.style.width = width + 'px'
            canvas.style.height = height + 'px'
            const context = canvas.getContext('2d')
            context.scale(2, 2)
            const options = {
                backgroundColor: null,
                canvas,
                useCORS: true,
            }
            html2canvas(canvasBox, options).then((canvas) => {
                // toDataURL 图片格式转成 base64
                let dataURL = canvas.toDataURL('image/png')
                console.log(dataURL)
                // this.downloadImage(dataURL)
            })
        },

        templateSave() {
            let this_ = this
            this.dialogFormVisible = false

            let data = {
                canvasData: JSON.stringify(this_.componentData),
                canvasStyle: JSON.stringify(this_.canvasStyleData),
                ...this_.form,
            }

            let template = localStorage.getItem('template')
            if (template) {
                data = {
                    ...JSON.parse(template),
                    ...data,
                }
            }

            let token = this.$route.query.token
            axios.post(this.hostUrl+'/api/template/add', data, {
                headers: {
                    token,
                },
            }).then(res => {
                if (!'200'==res.data.code) {
                    this.$message.error('保存失败：'+res.data.message)
                    return
                }

                let url = 'http://'+res.data.data.domain+'.loveyou520.top/'

                this.$alert(url, '恭喜您创建成功', {
                    confirmButtonText: '立即查看',
                    callback: action => {
                        window.location.href = url
                    },
                })
            })
        },

        templateUpdate() {
            let this_ = this

            let templateId = this.$route.query.templateId
            let data = {
                canvasData: JSON.stringify(this_.componentData),
                canvasStyle: JSON.stringify(this_.canvasStyleData),
                id: templateId,
            }

            let token = this.$route.query.token
            axios.post(this.hostUrl+'/api/template/templateUpdate', data, {
                headers: {
                    token,
                },
            }).then(res => {
                if (!'200'==res.data.code) {
                    this.$message.error('保存失败：'+res.data.message)
                    return
                }

                this.$message({
                    message: '更新成功',
                    type: 'success',
                })
            })
        },

        save() {
            // this.imageToFile()
            let templateId = this.$route.query.templateId
            if (templateId) {
                this.templateUpdate()
            } else {
                this.dialogFormVisible = true
            }
        },

        clearCanvas() {
            this.$store.commit('setComponentData', [])
            this.$store.commit('recordSnapshot')
            localStorage.removeItem('templateId')
        },

        handlePreviewChange() {
            this.$store.commit('setEditMode', 'edit')
        },
    },
}
</script>

<style lang="scss" scoped>
.toolbar {
    height: 40px;
    line-height: 40px;
    background: #fff;
    border-bottom: 1px solid #ddd;
    padding-left: 10px;
    display: flex;
    align-items: center;

    .iconfont{
        margin-left: 15px;
    }

    .iconfont:hover {
        cursor: pointer;
    }

    .canvas-config {
        display: flex;
        margin-left: 10px;
        font-size: 14px;
        color: #606266;
        align-items: center;

        input {
            width: 50px;
            margin-left: 10px;
            outline: none;
            padding: 0 5px;
            border: 1px solid #ddd;
            color: #606266;
        }

        span {
            margin-left: 10px;
        }
    }

    .insert {
        display: inline-block;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        background: #FFF;
        border: 1px solid #DCDFE6;
        color: #606266;
        -webkit-appearance: none;
        text-align: center;
        box-sizing: border-box;
        outline: 0;
        margin: 0;
        transition: .1s;
        font-weight: 500;
        padding: 9px 15px;
        font-size: 12px;
        border-radius: 3px;
        margin-left: 10px;

        &:active {
            color: #3a8ee6;
            border-color: #3a8ee6;
            outline: 0;
        }

        &:hover {
            background-color: #ecf5ff;
            color: #3a8ee6;
        }
    }
}
</style>